$success: #739e73;
$danger: #a90329;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "./assets/smartadmin-production.css";

.marzhaev {
  border: 3px;
}

/* BOOTSTRAP COMPATABILITY START */
body {
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
  font-size: 13px;
  color: #333333;
  background-color: #ffffff;
  line-height: 1.5;
}
a {
  color: #3276b1;
}
a:focus {
  color: #214e75;
  text-decoration: none;
}
.img-rounded {
  border-radius: 3px;
}
.img-thumbnail {
  border-radius: 2px;
}
hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border-top: 1px solid #eeeeee;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #999999;
}
h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 18px;
  margin-bottom: 9px;
}
h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 65%;
}
h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 9px;
  margin-bottom: 9px;
}
h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
  font-size: 75%;
}
h1, .h1 {
  font-size: 24px;
}
h2, .h2 {
  font-size: 22px;
}
h3, .h3 {
  font-size: 19px;
}
h4, .h4 {
  font-size: 18px;
}
h5, .h5 {
  font-size: 17px;
}
h6, .h6 {
  font-size: 15px;
}
p {
  margin: 0 0 9px;
}
@media (min-width: 768px) {
  .lead {
    font-size: 19.5px;
  }
}

small, .small {
  font-size: 92%;
}

.text-muted {
  color: #999999;
}
.text-primary {
  color: #3276b1;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #275b89;
}
.text-success {
  color: #468847;
}
a.text-success:hover,
a.text-success:focus {
  color: #356635;
}
.text-info {
  color: #346597;
}
a.text-info:hover,
a.text-info:focus {
  color: #274c71;
}
.text-warning {
  color: #c09853;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #a47e3c;
}
.text-danger {
  color: #b94a48;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #953b39;
}
.bg-primary {
  color: #fff;
  background-color: #3276b1;
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #275b89;
}
.bg-info {
  background-color: #d6dde7;
}
a.bg-info:hover,
a.bg-info:focus {
  background-color: #b6c2d4;
}
.page-header {
  padding-bottom: 8px;
  margin: 36px 0 18px;
  border-bottom: 1px solid #eeeeee;
}
ul, ol {
  margin-top: 0;
  margin-bottom: 9px;
}
dl {
  margin-bottom: 18px;
}
abbr[data-original-title] {
  border-bottom: 1px dotted #999999;
}
blockquote {
  padding: 9px 18px;
  margin: 0 0 18px;
  font-size: 16.25px;
  border-left: 5px solid #eeeeee;
}
blockquote footer, blockquote small, blockquote .small {
  color: #999999;
}
address {
  margin-bottom: 18px;
}
code {
  border-radius: 2px;
}
pre {
  padding: 8.5px;
  margin: 0 0 9px;
  font-size: 12px;
  border-radius: 2px;
}
.container {
  padding-left: 13px;
  padding-right: 13px;
}
@media (min-width: 768px) {
  .container {
    width: 746px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 966px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1166px;
  }
}
.row {
  margin-left: -13px;
  margin-right: -13px;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left: 13px;
  padding-right: 13px;
}
caption {
  color: #999999;
}
.table {
  margin-bottom: 18px;
}
.table-hover > tbody > tr:hover {
  background-color: #ecf3f8;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #ecf3f8;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #d9e7f1;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d6dde7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c6d0de;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    margin-bottom: 13.5px;
  }
}
legend {
  margin-bottom: 18px;
  font-size: 19.5px;
}
output {
  font-size: 13px;
}
.form-control {
  height: 32px;
  font-size: 13px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 32px;
  }
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg,
  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"] {
    line-height: 45px;
  }
}
.checkbox label {
  min-height: 18px;
}
.form-control-static {
  min-height: 0px;
}
.input-sm {
  border-radius: 2px;
}
.form-group-sm .form-control {
  border-radius: 2px;
}
.form-group-sm .form-control-static {
  min-height: 30px;
}
.input-lg {
  height: 45px;
  font-size: 17px;
  line-height: 1.33;
  border-radius: 3px;
}
select.input-lg {
  height: 45px;
  line-height: 45px;
}
.form-group-lg .form-control {
  height: 45px;
  font-size: 17px;
  line-height: 1.33;
  border-radius: 3px;
}
.form-group-lg select.form-control {
  height: 45px;
  line-height: 45px;
}
.form-group-lg .form-control-static {
  height: 45px;
  min-height: 35px;
  font-size: 17px;
  line-height: 1.33;
}
.has-feedback .form-control {
  padding-right: 40px;
}
.form-control-feedback {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.form-group-lg .form-control + .form-control-feedback {
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #468847;
}
.has-success .form-control {
  border-color: #468847;
}
.has-success .form-control:focus {
  border-color: #356635;
}
.has-success .input-group-addon {
  color: #468847;
  border-color: #468847;
}
.has-success .form-control-feedback {
  color: #468847;
}
.has-warning.checkbox-inline label {
  color: #c09853;
}
.has-warning .form-control {
  border-color: #c09853;
}
.has-warning .form-control:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.has-warning .input-group-addon {
  color: #c09853;
  border-color: #c09853;
  background-color: #fcf8e3;
}
.has-warning .form-control-feedback {
  color: #c09853;
}
.has-error.checkbox-inline label {
  color: #b94a48;
}
.has-error .form-control {
  border-color: #b94a48;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #953b39;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}
.has-error .input-group-addon {
  color: #b94a48;
  border-color: #b94a48;
  background-color: #f2dede;
}
.has-error .form-control-feedback {
  color: #b94a48;
}
.has-feedback label ~ .form-control-feedback {
  top: 23px;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 25px;
}
.form-horizontal .form-group {
  margin-left: -13px;
  margin-right: -13px;
}
.form-horizontal .has-feedback .form-control-feedback {
  right: 13px;
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    font-size: 17px;
  }
}
.btn, .btn-xs {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}
.btn-primary {
  color: #ffffff;
  background-color: #3276b1;
  border-color: #2c699d;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: #275b89;
  border-color: #10263a;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #275b89;
  border-color: #1f496d;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #275b89;
  border-color: #1f496d;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #ffffff;
  background-color: #1f496d;
  border-color: #10263a;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #3276b1;
  border-color: #2c699d;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: rgb(255, 255, 255);
  background-color: rgb(50, 118, 177);
  border-color: rgb(44, 105, 157);
}
.btn-primary .badge {
  color: #3276b1;
  background-color: #ffffff;
}
.btn-success {
  color: #ffffff;
  border-color: #659265;
}
.btn-success:focus,
.btn-success.focus {
  color: #ffffff;
  background-color: #5b835b;
  border-color: #314731;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #5b835b;
  border-color: #4c6e4c;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff;
  border-color: #659265;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #5b835b;
  border-color: #4c6e4c;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #ffffff;
  background-color: #4c6e4c;
  border-color: #314731;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  border-color: #659265;
}
.btn-success .badge {
  background-color: #ffffff;
}
.btn-info {
  color: #ffffff;
  background-color: #57889c;
  border-color: #4e7a8c;
}
.btn-info:focus,
.btn-info.focus {
  color: #ffffff;
  background-color: #456b7b;
  border-color: #20323a;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #456b7b;
  border-color: #385764;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #456b7b;
  border-color: #385764;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #ffffff;
  background-color: #385764;
  border-color: #20323a;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: #57889c;
  border-color: #4e7a8c;
}
.btn-info .badge {
  color: #57889c;
  background-color: #ffffff;
}
.btn-warning {
  color: #ffffff;
  background-color: #c79121;
  border-color: #b1811d;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #9b711a;
  border-color: #44310b;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #9b711a;
  border-color: #7d5b15;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #9b711a;
  border-color: #7d5b15;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: #ffffff;
  background-color: #7d5b15;
  border-color: #44310b;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #c79121;
  border-color: #b1811d;
}
.btn-warning .badge {
  color: #c79121;
  background-color: #ffffff;
}
.btn-danger {
  color: #ffffff;
  border-color: #900323;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #ffffff;
  background-color: #77021d;
  border-color: #130005;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #77021d;
  border-color: #540114;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #77021d;
  border-color: #540114;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #ffffff;
  background-color: #540114;
  border-color: #130005;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  border-color: #900323;
}
.btn-danger .badge {
  background-color: #ffffff;
}
.btn-link {
  color: #3276b1;
  font-weight: normal;
  border-radius: 0;
}
.btn-link:hover,
.btn-link:focus {
  color: #214e75;
  text-decoration: none;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #999999;
  text-decoration: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}

/* MArzhaev, 21-03-2023. Make buttons stack nicely */
/* Make sharp edges */
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .btn-xs,
.btn-group > .btn-group:not(:last-child) > .btn-sm,
.btn-group > .btn-group:not(:last-child) > .btn-lg,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-xs:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-sm:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-lg:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Make sharp edges */
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .btn-xs,
.btn-group > .btn-group:not(:first-child) > .btn-sm,
.btn-group > .btn-group:not(:first-child) > .btn-lg,
.btn-group > .btn:not(:first-child),
.btn-group > .btn-xs:not(:first-child),
.btn-group > .btn-sm:not(:first-child),
.btn-group > .btn-lg:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* Remove separation between buttons */
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child),
.btn-group > .btn-xs:not(:first-child),
.btn-group > .btn-sm:not(:first-child),
.btn-group > .btn-lg:not(:first-child) {
    margin-left: -1px;
}

/* MArzhaev, 21-03-2023. Make tables look nice */
/* Make tables screen-wide and with less borders */
table {
    border-collapse: collapse;
    width: 100%;
}

/* Add borders to visually distinguish rows */
.table td, .table th {
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table-bordered, .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
}

/* MArzhaev, 21-03-2023. Make inputs */
.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition-behavior: normal, normal;
    transition-duration: 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
    transition-property: border-color, box-shadow;
}

/* MArzhaev, 26-01-2018. Stopped comparing raw bootstrap with smartadmin's at around line 3500 */
/* BOOTSTRAP COMPATABILITY END */

#logo{
    margin-top: 8px;
}
#header{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#header > .pull-right{
  float: none!important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
}

.header-search.pull-right{
  width: 100%;
  padding-left: 6px;
}

#fullscreen, .header-search.pull-right{
  margin: 0
}

.table.table-bordered>tbody td{
    vertical-align: middle;
}
.pager{
    cursor: pointer;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.pager>li>.page-indicator:hover{
    background-color: #ffffff;
    color: #3276b1;
    cursor: default;
}
.well-white{
    background-color: #ffffff;
}
.prod-review-title{
    margin-bottom: 0; margin-top: 12px;
}
.prod-review-title+form{
    width: 100%;
    margin-top: 12px;
}
.prod-review-title+form>.editable-controls.form-group{
    width: 100%;
    padding-right: 95px;
}
.prod-review-title+form>.editable-controls.form-group>input{
    min-width: 100%;
}
.category-list{
    margin: 10px 0 0 0;
}
.category-item{
    line-height: 1.4em;
    margin: 0 0 8px;
    cursor: pointer;
}
.category-item.pdf-item{
    margin-top: 10px;
}
.category-item>a{
    color: #4c4f53;
    position: relative;
    top: -2px;
}
.category-item>a:hover{
    color: #3276b1;
}
.prod-review-info-section{
    margin-top: 20px;
}
.prod-review-info-section>.item{
    max-width: 400px;
}
.same-product-item{
    display: block;
    margin: 3px 0;
    padding: 10px;
    border: 1px dotted #ddd;
    background: rgba(0,0,0,.02);
    text-align: left;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    cursor: pointer;
}
.same-product-item:hover{
    background: rgba(0,0,0,.08);
}
.product-thumb{
    padding-left: 0;
}
.product-thumb>img{
    max-width: 100%;
}
.product-thumb>button{
    width: 100%;
    white-space: normal;
}
.product-mif-c{
    padding-right: 0;
}
.no-warp{
    white-space: nowrap;
}
.not-selectable{
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.center-item-text{
    text-align: center;
}
.display-flex{
    display: inline-flex;
    display: -webkit-flex;
    display: flex;
}
form-nav-buttons .btn>.fa{
    margin-right: 4px;
}
form-nav-buttons{
    margin-bottom: 10px;
}
form-nav-buttons[data-template='table']{
    display: flex;
    display: -webkit-flex;
    display: inline-flex;
    margin-bottom: 0;
}
form-nav-buttons[data-template='table']>div{
    width:28px;
    height: 28px;
    line-height: 24px;
}
form-nav-buttons[data-template='table']>a{
    width:28px;
    height: 28px;
    line-height: 24px;
}
form-nav-buttons[data-template='table'] .btn>.fa{
    margin-right: 0;
}
.un-float{
    clear: both;
    display: block;
}
.table-margined-top{
    margin-top: 50px;
}
.font-bold{
    font-weight: bold;
}
.btn[disabled]{
    opacity: 0.4;
}
.table-input{
    min-width: 60px;
}
.append-product-row>td{
    vertical-align: top!important;
}
.search-item img{
    width: 60px;
    border: 1px solid #fff;
    outline: 1px solid #bfbfbf;
    min-width: 60px;
    max-width: 60px;
}
.search-item .ui-select-choices-row-inner{
    display: table;
    width: 100%;
}
.search-item .search-tr{
    display: table-row;
    height: 56px;
}
.search-item .search-td{
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    padding: 8px 10px;
    float: none!important;
}
.a-row-add{
    transition:0.5s linear all;
}
.a-row-add.ng-enter{
    background-color: #cde0c4;
    opacity: 0;
}
.a-row-add.ng-enter-active{
    opacity: 1;
}
.a-row-add.ng-leave{
    background-color: #c26565;
    opacity: 1;
}
.a-row-add.ng-leave-active{
    opacity: 0;
}
.table-bordered tr.row-archived{
  background-color: #ffcdd2;
}
.right{
  float: right!important;
}
.form-control{
    min-height: 32px;
    height: auto;
}
.ui-select-status>span{
    white-space: normal;
}
.td-ui-select{
    min-width: 132px;
    width: 100%;
}
textarea{
    resize: none;
}
.notifications-pane .notification.seen {opacity: 0.5}
.notification-body{
    padding: 0 3px;
}
a.dz-download{
    background-image: -webkit-linear-gradient(top, #38c56f 0, #389154 100%);
    border-radius: 2px;
    border: 1px solid #3276b1;
    display: block;
    padding: 4px 5px;
    text-align: center;
    color: #fff;
    margin-top: 26px;
    margin-bottom: -15px;
}
a.dz-download:last-child{
    margin-bottom: 0;
}
.dz-default.dz-message{
    text-align: center;
}
.file-worker-modal>.modal-dialog{
    width: 800px;
}
.dz-filename-input{
    height: 100%!important;
    position: relative;
    z-index: 1;
}
.btn.dz-files-upload-button, .btn.dz-files-upload-button:active{
    position: absolute;
    margin-left: 100%;
    left: -156px;
    top: -16px;
}
.dropzone .dz-preview .dz-details{
    width: 120px;
    height: 120px;
    background: transparent;
}
.dropzone .dz-preview:hover .dz-details{
    background: rgba(255, 255, 255, 0.9);
}
.dropzone .dz-image{
    position: absolute;
}
.dropzone .dz-preview .dz-progress{
    z-index: 2;
}
.text-info{
    color: #ff0000!important;
}
.full-width{
    width: 100%;
}
form input.ng-invalid.ng-touched, form input.ng-invalid.ng-untouched{
    border-color: #a90329!important;
}
.p-novalid-msg{
    margin: 6px 10px 0 0;
    float: right;
}
textarea.form-control.form-description{
    height: 100px;
}
.ribbon-preloader{
    float: right;
    margin-top: 5px;
    color: #E4E4E4;
}
#ribbon{
    display: inline-block;
    width: 100%;
}
@media screen and (max-width: 679px) {

    .ribbon-preloader{
        color: #333;
    }
}
.table-bordered tr{
    background-color: #ffffff;
}
[data-dz-name]{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
a[data-toggle="tab"]{
    cursor: pointer;
}
.login-back{
    background: url("./assets/img_2370.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
}
.txt-logo-orange{
    color: rgb(241, 125, 27);
}
.flipper {
    margin:0 auto;
    position:relative;
    -moz-perspective: 800px;
    -webkit-perspective: 800px;
    perspective: 800px;
}
.front, .back {
    width:100%;
    height: 316px;
    position:absolute;
    top:0;
    left:0;

    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    -moz-transition:0.8s;
    -webkit-transition:0.8s;
    transition:0.8s;
}
.front {
    z-index:100;
}
.back{
    z-index:1;
    opacity:0;

    -moz-transform:rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    transform:rotateY(180deg);
}
.flipper.flipped .front{
    opacity:0;
    -moz-transform:rotateY(-180deg);
    -webkit-transform:rotateY(-180deg);
    transform:rotateY(-180deg);
}
.flipper.flipped .back{
    opacity:1;

    -moz-transform:rotateY(0deg);
    -webkit-transform:rotateY(0deg);
    transform:rotateY(0deg);
}
#main.login-page{
    overflow: hidden; margin-left: 0;
}
#content.login-page{
    padding-top: 36px;
}
.link, marker {
    stroke: #000;
    stroke-width: 1.5px;
}

.node.selected>.circle {
    stroke: #000;
    stroke-width: 1.5px;
}
.force-node-label{
    white-space: normal;
    text-overflow: ellipsis;
    overflow: visible;
    line-height: 20px;
    font-size: 13px;
    text-align: center;
    text-shadow: 0 0 2px white;
    cursor: default;
}
path.link {
    fill: none;
    stroke: #666;
    stroke-width: 1.5px;
}
path.link:hover {
    fill: none;
    stroke: #a90329;
    stroke-width: 2.0px;
}
.widget-tollb-row-with-t{
    margin-left: -10px;
    margin-right: -10px;
}
.dshb-parse-log-c, .dshb-srch-log-c{
    height: 500px;
}
.bordered-container {
    border: 1px solid rgb(221, 221, 221);
    padding: 10px;
}
.bordered-container>.title{
    position: absolute;
    margin-top: -31px;
    background-color: #ffffff;
    margin-left: 14px;
}
.bordered-container.titled{
    padding-top: 20px;
}
.bordered-container .item{
    margin-bottom: 10px;
}
.float-none{
    float: none;
}
.f-r{
    float: right;
}
.m-r-10{
    margin-left: 10px;
}
.eqo-th-iconed{
    width: 20px;
}
.editable{
    cursor: default;
}
.editable-input{
    min-height: 32px;
    height: 32px;
    max-width: 180px;
}
.eqo-td-rm-ico{
    color: rgb(221, 221, 221);
}
.eqo-td-rm-ico:hover{
    color: rgb(51, 51, 51);
}
.eqo-centred-modal>.modal-dialog{
    margin-top: 130px;
}
.eqo-td-circle-ico{
    width: 11px;
    height: 11px;
    border:  1px solid rgb(51, 51, 51);
    border-radius: 11px;
    background-color: white;
    cursor: pointer;
    display: inline-block;
}
.eqo-td-circle-ico.active{
    background-color: rgb(51, 51, 51);
}
td[hidden-buttons] i.blue-hover {
    opacity: 0;
    margin-left: 10px;
    height: 20px;
    padding: 3px;
    width: 20px;
    -webkit-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    -ms-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    transition: opacity 500ms;
}

td[hidden-buttons]:hover i.blue-hover {
    opacity: 1;
}

form.eqo-add-elem-form input.ng-invalid.ng-touched, form.eqo-add-elem-form input.ng-invalid.ng-untouched{
    border-color: #ccc!important;
}
form.eqo-add-elem-form input.inp-error.ng-invalid.ng-touched, form.eqo-add-elem-form input.inp-error.ng-invalid.ng-untouched{
    border-color: #a90329!important;
}
a.black {
    color: rgba(51,51,51,1);
}
.scrollable-y{
    overflow-y: auto;
}
.td-nb-btn{
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 4px;
}
.td-nb-btn.themed{
    color: rgb(76, 79, 83);
    opacity: 0;
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -ms-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    transition: opacity 300ms;
}
td:hover .td-nb-btn.themed{
    opacity: 1;
    color: rgba(76, 79, 83, 1);
}
.editable-click{
    cursor: pointer;
}
/* UI copy to buffer */
.te-c-buffer-input{
    position: absolute;
    pointer-events: none;
    opacity: 0;
    z-index: -9999999;
    max-width: 10px;
    width: 10px;
}
te-copy-to-buffer> i.te-c-to-buffer-ico{
    position: absolute;
    opacity: 0;
    /*margin-left: 10px;*/
    height: 20px;
    padding: 3px;
    width: 20px;
    -webkit-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    -ms-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    transition: opacity 500ms;
}
te-copy-to-buffer:hover> i.te-c-to-buffer-ico{
    opacity: 1;
}
/* end copy to buffer */
.scroll-x{
    overflow-x: auto;
}
.table-fixed{
    table-layout: fixed;
}
.table-fixed td{
    word-wrap: break-word;
}
.nv-file-over { border: dotted 3px red; }
.link {
  stroke: #000;
  stroke-width: 1.5px;
}

.node {
  cursor: move;
  fill: #ccc;
  stroke: #000;
  stroke-width: 1.5px;
}

.node {
    fill: #ccc;
    stroke: #fff;
    stroke-width: 2px;
}

.link {
    stroke: #777;
    stroke-width: 2px;
}
td .editable-controls>input[type="number"]{
    width: 100%;
    max-width: 100%;
}
.summary-draggable-panel{
    position: fixed;
    top: 100px;
    right: 344px;
    width: 0;
    height: 0;
    z-index: 1;
}
.summary-draggable-panel .content{
    padding: 30px;
    width: 300px;
    background-color: white;
    border: 1px solid #474544;
    -webkit-box-shadow: 1px 1px 10px #474544;
    -moz-box-shadow: 1px 1px 10px #474544;
    box-shadow: 1px 1px 10px #474544;
    text-align: center;
    overflow-y: auto;
}
.table-bordered tr.selected{
    background-color: #ecf3f8!important;
}
.order-items.ui-sortable-helper{
    height: auto!important;
}
.eqo-schema-control-p{
    position: absolute;
    width: 230px;
    right: 0;
    top: 45px;
    border: 1px solid #4c4f53;
}
.eqo-schema-control-p>header{
    background-color: #4c4f53;
    color: white;
}
.eqo-schema-control-p .eqo-schema-elem>input:checked+i{
    border-color: red;
}
.eqo-schema-control-p .eqo-schema-elem>i:before{
    background-color: red;
}
.eqo-schema-control-p .eqo-schema-prod>input:checked+i{
    border-color: #008000;
}
.eqo-schema-control-p .eqo-schema-prod>i:before{
    background-color: #008000;
}
te-copy-to-buffer .fa-copy.animate {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
.w-88-f{
    width: 88px;
    float: left;
}
.p-l-15{
    padding-left: 15px;
}
.irs-bar, .irs-bar-edge{
    height: 6px;
    top: 25px;
    background: #3276b1;
}
.editable-textarea-fw ~ form.editable-textarea{
    width: 100%;
}
.editable-textarea-fw ~ form.editable-textarea>.editable-controls{
    width: 100%;
    display: flex;
}
.editable-textarea-fw ~ form.editable-textarea>.editable-controls>textarea{
    width: 100%;
    max-width: 100%;
    height: 100px;
}
.select2-search{
    display: flex;
}
.select2-search input{
    width: 100%!important;
}
.login-back>.back-overflow{
    height: 100%;
    background-color: rgba(0,0,0,0.6);
}
#lock{
    padding: 45px;
    height: 100%;
    overflow: scroll;
}
.full-editable-area{
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    height: 100px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
}
.full-editable-area>[editable-textarea]{
    word-break: normal;
    white-space: normal;
}
.full-editable-area>form{
    height: 100%;
    display: flex;
    flex: 100 1 0%;
    flex-direction: row;
}
.full-editable-area>form>.editable-controls.form-group{
    flex: 100;
    display: flex;
    flex-direction: row;
}
.full-editable-area>form>.editable-controls.form-group>textarea.editable-input.form-control{
    width: 100%;
    max-width: 100%;
    white-space: normal;
    word-break: normal;
    font-size: 13px;
}
.modal-backdrop {
  z-index: 0;
}
.no-events{
  pointer-events: none;
}
.def-tabset .tab-pane{
  padding-top: 20px!important;
}
.def-tabset .tab-pane section{
  padding-left: 10px;
}
.btn-margin{
  margin-bottom: 10px
}
.dropdown-menu.green>li>a:hover{
  background-color: #739e73;
}
.dropdown-menu.green>li{
  cursor: pointer;
}
.extra-nav-btns{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.extra-nav-btns>form-nav-buttons{
  margin-right: -2px;
}
.p-b-20{
  padding-bottom: 20px;
}
.m-b-9{
  margin-bottom: 9px;
}
.c-orders-list{
  padding: 10px 10px 0 10px;
}
.table-hover tr.no-hover:hover{
  background-color: white;
}
.flex{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex: 1;
}
.flex-100{
  flex: 1;
}
.wrap{
  flex-wrap: wrap;
}
.layout-row{
  flex-direction: row;
}
.layout-align-start-center{
  align-items: center;
  justify-content: flex-start;
}
.layout-align-space-between-center{
  align-items: center;
  justify-content: space-between;
}
.search-toggle>span{
  margin-left: 5px;
}
.float-right{
  float: right;
}
.list-filter-switcher{
  float: right;
  padding-top: 5px;
}
.onoffswitch{
  margin-top: 0;
  margin-bottom: 0;
}
.onoffswitch-label{
  margin: 0;
}

/* Toggle switch */
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, ".SFNSText-Regular", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before, .toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #56c080;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.navigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-bottom: 37px;
    width: 260px;
    background: #444;
    color: #999;
    line-height: 1.5;
    user-select: none;
}

.navigation__burger {
    position: fixed;
    top: 9px;
    left: 0;
    z-index: 10;
    width: 36px;
    height: 36px;
    font-size: 18px;
    border: none;
    border-radius: 0 4px 4px 0;
    background: #444;
    color: #ddd;
}

.navigation__content {
    height: 100%;
    overflow-y: auto;
}

.navigation__header {
    padding: 10px 20px;
}

.navigation__collapser {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    padding: 0;
    margin: 13px 20px;
}

.navigation__profile,
.navigation__search,
.navigation__group,
.navigation__footer {
    border-top: 1px solid #555;
    font-size: 16px;
}

.navigation__profile,
.navigation__search {
    padding: 10px 20px;
}

.navigation__profile {
    overflow: hidden;
}

.navigation__profile-pic {
    float: left;
    overflow: hidden;
    margin-right: 15px;
    width: 56px;
    height: 56px;
    background: #ccc;
    border-radius: 3px;
    text-align: center;
}

.navigation__profile-role {
    font-size: 12px;
}

.navigation__search-group {
    display: table;
    border-collapse: separate;
}

.navigation__search-input {
    display: table-cell;
    padding: 6px 12px;
    width: 100%;
    font: inherit;
    background: #555;
    border: none;
    border-radius: 4px 0 0 4px;
    color: #e8e8e8;
}

.navigation__search-input::-webkit-search-cancel-button{
    -webkit-appearance: none;
}

.navigation__search-submit {
    display: table-cell;
    width: 1%;
    vertical-align: middle;
}

.navigation__search-button {
    width: 36px;
    height: 36px;
    background: #4d4d4d;
    border: 1px solid #4d4d4d;
    border-radius: 0 4px 4px 0;
    transition: background .2s ease, border-color .2s ease;
}

.navigation__search-button:hover {
    background: #404040;
    border-color: #404040;
}

.navigation__search-button:focus {
    border: 1px dashed #999;
}

.navigation__search-button:disabled {
    background: #555;
    border-color: #555;
}

.navigation__group-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation__group-header {
    padding: 15px 20px 5px;
    font-size: 14px;
    font-weight: bold;
}

.navigation__group-item {
    position: relative;
    display: block;
    padding: 8px 20px;
    color: inherit;
    text-decoration: none !important;
    cursor: pointer;
    transition: color .2s ease;
}

.navigation__group-item-icon {
    margin-right: 10px;
    font-size: 12px !important;
    width: 30px;
    height: 30px;
    line-height: 30px !important;
    text-align: center;
    border-radius: 4px;
    background: #4d4d4d;
    transition: color .2s ease, text-shadow .2s ease;
}

.navigation__group-item-chevron {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto;
    height: 1em;
    transition: transform .2s ease;
}

.navigation__group-dropdown {
    list-style: none;
    margin: 0;
    padding: 5px 0;
    font-size: 13px;
    background: #555;
}

.navigation__group-dropdown-item {
    position: relative;
    display: block;
    text-decoration: none !important;
    padding: 8px 30px 8px 60px;
    color: inherit;
    cursor: pointer;
    transition: color .2s ease;
}

.navigation__group-dropdown-circle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 42px;
    margin: auto;
    height: 1em;
    font-size: 8px;
    transition: color .2s ease, text-shadow .2s ease;
}

.navigation__footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 4px;
    background: #555;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, .25);
}

.navigation__footer-button {
    padding: 0;
    width: 30px;
    height: 30px;
    background: transparent;
    border: 1px dashed transparent;
    border-radius: 4px;
    font-size: 20px;
    transition: color .2s ease, border-color .2s ease;
}

.navigation__footer-button:focus {
    border-color: #999;
}

.navigation__group-item:hover,
.navigation__group-item:focus,
.navigation__group-item_active,
.navigation__group-dropdown-item:hover,
.navigation__group-dropdown-item:focus,
.navigation__group-dropdown-item_active,
.navigation__footer-button:hover {
    color: #ddd;
}

.navigation__group-item:hover .navigation__group-item-icon,
.navigation__group-item_active .navigation__group-item-icon,
.navigation__group-dropdown-item:hover .navigation__group-dropdown-circle,
.navigation__group-dropdown-item_active .navigation__group-dropdown-circle {
    color: rgb(245, 143, 49);
    text-shadow: 0 0 10px rgba(245, 143, 49, .5);
}
.badge {
  margin-right: 0.375rem !important;
  margin-bottom: 1rem !important;
}

/* Texenergo in-house */

button {
  color: inherit;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

.table > tbody > tr > td.info {
  font-weight: bold;
}

/* Editables - https://github.com/mercurymedia/elm-datetime-picker/blob/master/css/SingleDatePicker.css*/

.alert-danger .form-control-static span {
  color: white;
}

/* Time picker */
.elm-datetimepicker-single--picker-container {
    background-color: #fff;
    position: absolute;
    color: #22292f;
    font-size: .875rem;
    padding: .75rem;
    border-radius: 5px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.elm-datetimepicker-single--calendar-container {
    margin-bottom: .5rem;
}

.elm-datetimepicker-single--calendar-header {
    padding: .25rem 0 0 0;
    font-weight: bold;
}

.elm-datetimepicker-single--calendar-header-row {
    display: flex;
    justify-content: space-between;
}

.elm-datetimepicker-single--calendar-header-text {
    display: inline-flex;
    align-items: center;
}

.elm-datetimepicker-single--calendar-header-chevron {
    display: inline-flex;
    align-items: center;
    padding: 0 .5rem 0 .5rem;
    cursor: pointer;
}

.elm-datetimepicker-single--calendar-header-chevron:hover {
    color: #38c172;
}

.elm-datetimepicker-single--calendar-header-week {
    display: flex;
    color: #a0aec0;
    font-weight: normal;
    padding: .5rem 0 .25rem 0
}

.elm-datetimepicker-single--calendar-header-day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
}

.elm-datetimepicker-single--calendar-month {
    height: 180px;
    width: 210px;
}

.elm-datetimepicker-single--calendar-week {
    display: flex;
}

.elm-datetimepicker-single--calendar-day {
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
    background-color: #fff;
    color: #22292f;
    cursor: pointer;
    border: none;
    outline: 0;
}

.elm-datetimepicker-single--calendar-day:hover {
    background-color: #a0aec0;
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--hidden {
    visibility: hidden;
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--disabled {
    color: #a0aec0;
    cursor: default;
    background-color: #fff;
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--picked {
    background-color: #3490dc;
    color: #fff;
}

.elm-datetimepicker-single--calendar-day.elm-datetimepicker-single--today {
    color: #3490dc;
}

.elm-datetimepicker-single--footer {
    width: 100%;
    border-top: 1px solid #a0aec0;
    padding: .25rem .75rem .25rem .75rem;
}

.elm-datetimepicker-single--time-picker-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.elm-datetimepicker-single--time-picker-toggle {
    display: flex;
    justify-content: center;
    cursor: pointer;
}


.elm-datetimepicker-single--date-display-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 20px;
    padding-top: 5px;
}

.elm-datetimepicker-single--time-picker {
    display: flex;
    justify-content: space-between;
    padding: .25rem 0 .25rem 0
}

.elm-datetimepicker-single--select-container {
    display: inline-flex;
}

.elm-datetimepicker-single--select {
    display: inline-flex;
}

.elm-datetimepicker-single--selection-time {
    margin-left: 5px;
}

.elm-datetimepicker-single--selection-time-danger {
    margin-left: 5px;
    color: red;
}

.elm-datetimepicker-single--select-spacer {
    display: inline-flex;
    padding: 0 .25rem 0 .25rem;
}
